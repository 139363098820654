import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonals({ uni }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="w-full py-8 mt-8">
      <div className="md:w-[90%] w-[100%] ">
        <div className="md:text-[28px] text-[20px] font-bold mb-4 ml-2">
          Our Placed <span className={`text-[${uni.textColor}]`}>Students</span>
        </div>
        <Slider {...settings}>
          {uni &&
            uni.ourPlacedStudent.map((item, ind) => {
              return (
                <div className="px-2" key={ind} py-4  border-2>
                  <div className="bg-[#f0f7ff] py-2 px-3 hover:bg-white hover:shadow-xl">
                    <div className="max-w-100 ">
                      <img src={item.perImg} className="mx-auto py-2 w-[10rem]  rounded-lg" alt="" />
                    </div>
                    <p className="text-center my-1 font-bold">{item.Name}</p>
                    <p className="text-center my-1 font-thin text-sm">
                      {item.work}{" "}
                    </p>
                    <div className="max-w-100">
                      <img src={item.Topimg} className="mx-auto py-2  " alt="" />
                    </div>
                    <p className=" md:h-[7rem]">{item.Review}</p>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonals;

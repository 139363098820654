import React from "react";
import { IoLogoWhatsapp, IoMdClose } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import im1 from "./screen/home/courses/image/Courses/1.png";
import im2 from "./screen/home/courses/image/Courses/2.png";
import im3 from "./screen/home/courses/image/Courses/3.png";
import im4 from "./screen/home/courses/image/Courses/7.png";
import im5 from "./screen/home/courses/image/Courses/8.png";
import im6 from "./screen/home/courses/image/Courses/9.png";
import im7 from "./screen/home/courses/image/Courses/10.png";
import im8 from "./screen/home/courses/image/Courses/11.png";

import qankit from "./images/qunatumUniversity/placedStudent/Arpit Dwivedi_Infosys.jpg";
import vidhudi from "./images/qunatumUniversity/placedStudent/Vidushi nautiyal_HCL Technologies.jpg";
import Aditya from "./images/qunatumUniversity/placedStudent/Aditya Sahu_Reliance Industries Limited.jpg";
import aditi from "./images/qunatumUniversity/placedStudent/Aditi Karnwal_Wipro.jpg";
import abhishek from "./images/qunatumUniversity/placedStudent/Abhishek Saini_IBM.jpg";
import qmayank from "./images/qunatumUniversity/placedStudent/qmayank.png";

import award5 from "../src/screen/home/OurEvent/Image/bschool.webp";
import award3 from "../src/screen/home/OurEvent/Image/award3.webp";
import award1 from "../src/screen/home/OurEvent/Image/award1.webp";
import award2 from "../src/screen/home/OurEvent/Image/award2.webp";
import award4 from "../src/screen/home/OurEvent/Image/award4.webp";
import award6 from "../src/screen/home/OurEvent/Image/award6.webp";
import marwadie1 from "./images/marwadiUniversity/event/1 (1).jpg";
import marwadie2 from "./images/marwadiUniversity/event/1 (2).jpg";
import marwadie3 from "./images/marwadiUniversity/event/1 (3).jpg";
import marwadie4 from "./images/marwadiUniversity/event/1 (4).jpg";
import marwadie5 from "./images/marwadiUniversity/event/1 (5).jpg";
import marwadie6 from "./images/marwadiUniversity/event/1 (6).jpg";
import karnawati from "./images/karnawatiUniversity/karnawati.jpeg";
import karah1 from "./images/karnawatiUniversity/hiringPartner/1.png";
import karah2 from "./images/karnawatiUniversity/hiringPartner/2.png";
import karah3 from "./images/karnawatiUniversity/hiringPartner/3.png";
import karah4 from "./images/karnawatiUniversity/hiringPartner/4.png";
import karah5 from "./images/karnawatiUniversity/hiringPartner/5.png";
import karah6 from "./images/karnawatiUniversity/hiringPartner/6.png";
import karah7 from "./images/karnawatiUniversity/hiringPartner/7.png";
import karah8 from "./images/karnawatiUniversity/hiringPartner/8.png";
import karah9 from "./images/karnawatiUniversity/hiringPartner/9.png";
import karah10 from "./images/karnawatiUniversity/hiringPartner/10.png";
import karah11 from "./images/karnawatiUniversity/hiringPartner/11.png";
import karah12 from "./images/karnawatiUniversity/hiringPartner/12.png";
import ajayshah from "./images/karnawatiUniversity/placedStudent/Ajay-Shah.jpg";
import anushkashah from "./images/karnawatiUniversity/placedStudent/Anushka-Shah.jpg";
import shaishavimetha from "./images/karnawatiUniversity/placedStudent/Shaishavi-Mehta.jpg";
import srinidhi from "./images/karnawatiUniversity/placedStudent/Srinidhi.jpg";
import vanshika from "./images/karnawatiUniversity/placedStudent/Vanshika.jpg";
import vidhijain from "./images/karnawatiUniversity/placedStudent/Vidhi-Jain.jpg";
import Navbar from "./screen/home/navbar";
import Enquriyform from "./common/enquiryform/page";
import Footer from "./screen/home/Footer/Footer";
import Disclaimer from "./disclaimer";
import karnavati2 from "./screen/home/university-banner/image/01 (1).jpg";
import karnavati4 from "./screen/home/university-banner/image/01.jpg";

import BlogPost1 from "./screen/blog/blog1";
import BlogPost2 from "./screen/blog/blog2";
import PrivacyPolicy from "./screen/privacyPolicy";
const App = () => {
  const uni = {
    id: "Quantum-university",
    textColor:"#EA2284",
    university: "Quantum University",
    image: [karnavati2,karnavati4],
    courses: [
      {
        name: "BBA(Hons)",
        fee: "₹ 2,20,000/year",
        details: ["3+1 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Marketing",
          "Finance",
          "HR Management",
          "Digital Marketing",
          "Aviation",
          "Sports Management",
        ],
        location: "Roorkee",
        approval: true,
        image: im1, // image source for BBA
        description: "Description for BBA course goes here.",
      },
      {
        name: "MBA.",
        fee: "1,77,500/Year",
        details: ["2 Years", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "Available based on merit and research grants.",
        specialisation: [
          "Marketing",
          "Finance",
          "Human Resource (HR)",
          "Entrepreneurship And Family Business",
          " Data Analytics & Business Intelligence",
        ],
        location: "Roorkee",
        approval: true,
        image: im2, // image source for Ph.D.
        description: "Description for MBA program goes here.",
      },
      {
        name: "BA LLB (Hons.)",
        fee: "₹ 2,20,000/year",
        details: ["3+1 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          " Business Law",
          " IPL (Intellectual Property law)",
          "International Law",
          "Criminial Law",
        ],
        location: "Roorkee",
        approval: true,
        image: im3, // image source for LLM
        description: "Description for BA LLB (Hons.) course goes here.",
      },
      {
        name: "BA (Hons )‐LA",
        fee: "2,20,000/Year",
        details: ["3+1 Years", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "Available based on merit and financial need.",
        specialisation: [
          "Political Science & Public Affairs",
          "Event Management",
          " Accounting and Economics",
          "Psychology",
          " Journalism",
          "Ad & PR",
        ],
        location: "Roorkee",
        approval: true,
        image: im8, // image source for BA
        description: "Description for BA program goes here.",
      },
      {
        name: "BBA LLB(Hons.)",
        fee: "₹ 2,60,000/year",
        details: ["5 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          " Business Law",
          "Criminal Law",
          " IPL (Intellectual Property law)",
          "International Law",
        ],
        location: "Roorkee",
        approval: true,
        image: im1, // image source for BBA LLB
        description: "Description for BBA LLB course goes here.",
      },
      {
        name: "B.Tech",
        fee: "₹ 1,39,250/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          " Computer Science & Engineering",
          " CSE -AI & ML",
          "CSE- Cyber Security",
          " CSE-Data Science",
          " Information & Communication Technology",
        ],
        location: "Roorkee",
        approval: true,
        image: im7, // image source for B.Tech
        description: "Description for B.Tech course goes here.",
      },
      {
        name: "B.S. (Hons) Computer Science",
        fee: "₹ 2,20,000/year",
        details: ["3+1 Year", "On-campus","Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Data Science",
          " Artificial Intelligence & Machine Learning",
          "Forensic Science",
        ],
        location: "Roorkee",
        approval: true,
        image: im8, // image source for M.Tech
        description: "Description for   B.S(Hons) course goes here.",
      },
      {
        name: "BCA",
        fee: "₹ 80,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "According to merit and need-based.",
        specialisation: [
          "Computer Applications",
          "Software Development",
          "Web Development",
          "Networking",
          "Database Management",
        ],
        location: "Roorkee",
        approval: true,
        image: im4, // image source for BCA
        description: "Description for BCA course goes here.",
      },
      {
        name: "Integrated‐ B.S. +M.S. Cyber Security",
        fee: "₹ 2,20,000/year",
        details: ["5 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Political Science & Public Affairs",
          "Event Management",
          " Accounting and Economics",
          "Psychology",
          " Journalism",
          "Ad & PR",
        ],
        location: "Roorkee",
        approval: true,
        image: im5, // image source for MCA
        description: "Description for B.S+M.S course goes here.",
      },
      {
        name: "B.Com (Hons)‐LA ",
        fee: "₹ 2,20,000/year",
        details: ["3+1 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: ["Cyber Security"],
        location: "Roorkee",
        approval: true,
        image: im8, // image source for MCA
        description: "Description for B.Com (Hons)‐LA course goes here.",
      },
    ],
    event: [
      {
        img: award1,
        data: "31",
        month: "may, 2023",
        hed: "Karnavati University felicitated with EducationWorld Award",
        pre: "We are elated to announce that Karnavati University has won the EducationWorld award under the category ‘Campus Design Excellence!",
        time: "May 31, 2023",
        country: "United Kingdom",
        alt: "Augmented Reality Image",
        arrow: <FaArrowRight />,
      },
      {
        img: award2,
        data: "25",
        month: "jan, 2023",
        hed: "4 Star Ranking by Institution’s Innovation Council (IIC)",
        pre: "We are happy to announce that Karnavati University has achieved 4 Star Ranking for its continuous efforts to promote Innovation",
        time: "January 25, 2023",
        country: "United States",
        alt: "UX Research Image",
        arrow: <FaArrowRight />,
      },
      {
        img: award4,
        data: "21",
        month: "May, 2022",
        hed: "Top Design Institute of India by Competition Success Review (CSR)",
        pre: "Unitedworld Institute of Design, Karnavati University, Roorkee has been awarded the CSR Top Design Institute of India by the Chairman of the acclaimed Competition Success Review (CSR), Mr. SK Sachdeva.",
        time: "May 21, 2022",
        country: "Canada",
        alt: "Game Development Image",
        arrow: <FaArrowRight />,
      },
      {
        img: award6,
        data: "13",
        month: "may , 2019",
        hed: "Excellence in Education Award",
        pre: "Shri Ritesh Hada, President, Karnavati University, was felicitated by Shri Vijay Rupani, Hon’ble Chief Minister of Gujarat, for extraordinarily exceptional work done",
        time: "13 may,2019",
        country: "Australia",
        alt: "Cloud Security Image",
        arrow: <FaArrowRight />,
      },
      {
        img: award5,
        data: "27",
        month: "aprail 2019",
        hed: "UWSB ranks 24th (A++ Category)",
        pre: "Unitedworld School of Business (UWSB) – Ahmedabad Campus ranked 24th (A++ Category) 8th (Industry Interface) 10th (RoI) 10th (West Zone) 10th (Infrastructure) in the annual survey of Siliconindia Education What if not IIMs: B- Schools Survey 2017",
        time: "April 27, 2019",
        country: "Germany",
        alt: "Social Media Marketing Image",
        arrow: <FaArrowRight />,
      },
      {
        img: award3,
        data: "2",
        month: "may 2019",
        hed: "UID – Best Private Design College in India",
        pre: "Unitedworld Institute of Design (UID) has been conferred with the award “Best Private Design College in India” during the National Education Excellence Awards organised by one of the leading Brand Management Consulting & Research firm in India, World Business...",
        time: "2 May 2019",
        country: "France",
        alt: "img",
        arrow: <FaArrowRight />,
      },
    ],
    ourPlacedStudent: [
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Nice Experience, awesome learning and well maintained discipline.",
        perImg: qankit,
        Name: "Arpit diwedi",
        work: "Infosys",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Being at Quantum was one of the greatest experiences of my life. I learnt a lot of new things to grow my career.  ",
        perImg: vidhudi,
        Name: "Vidhushi Nautiiyal",
        work: "HCL Technologies",
      },
      {
        Topimg:
          " http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          " Quantum has not only given me education but has also helped me to build my personality, which helped me to shape my career. ",
        perImg: Aditya,
        Name: "Aditya Sahu ",
        work: "Reliance Industries",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "I number of companies that visit Quantum for placements is simply amazing. All the students get ample chance to get placed.",
        perImg: aditi,
        Name: "Aditi Karnwal ",
        work: "Wipro",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Being at Quantum was one of the greatest experiences of my life. I learnt a lot of new things to grow my career.   ",
        perImg: abhishek,
        Name: "Abhishek saini",
        work: "IBM",
      },
   
    ],
    applicationDeadline: "1 july",
    noScholarships: "22",
    amount: "variable",
    internationalStudents: "yes",
    scholarshipLink: "www.aapadhe.in",
    address: "Roorkee",
    loanBNK:
      "QUANTUM UNIVERSITY IS UGC APPROVED UNIVERSITY, SO ONCE YOU RECEIVE YOUR JOINING LETTER,YOU CAN CONTACT YOUR BANK FOR EDUCATION LOANS. FOR MORE",
    email: "karnawati@offical.com",
    phone: "+919257767661",
    hiringPartner: [
      karah1,
      karah2,
      karah3,
      karah4,
      karah5,
      karah6,
      karah7,
      karah8,
      karah9,
      karah10,
      karah11,
      karah12,
      karah4,
      karah7,
    ],
    studentEnroll: "125,300",
    registeredInstructor: "300",
    successRate: "100",
    successStory: [


      
      "https://www.youtube.com/embed/dcSsgQwzQcU",
      "https://www.youtube.com/embed/Je8EooLO5SM",
      "https://www.youtube.com/embed/O6P6xsji1fc",
      "https://www.youtube.com/embed/dcSsgQwzQcU",
      "https://www.youtube.com/embed/Je8EooLO5SM",
      "https://www.youtube.com/embed/O6P6xsji1fc",

    ],
    doubtSection: [
      {
        question: "Can I get direct admission in Quantum University?",
        answer1:
          "Admissions to any of the programs at Quantum is based on merit. As the first step, student needs to register with the college and deposit a registration fee of Rs. 1200/- along with the duly filled registration form.",
        
      },
      {
        question: "Is quantum university good for cse?",
        answer1:
          "In a very prestigious IMRB Ranking, Quantum has been ranked at the 2nd spot among the Engineering colleges of Uttarakhand. Career360 magazine has recognized Quantum amongst the top Engineering colleges in India.",
      },
      {
        question: "How old is quantum university?",
        answer1:
          "History. Quantum University Roorkee was founded in 2017 by industrialist Shri Shyam Sunder Goyal. The core programs initially offered by the college were in the disciplines of Engineering and Management. In 2018 the university started programs in Graduate Studies.",
      },
      {
        question: "What is the hostel fee in Quantum University Roorkee?        ",
        answer1:
          "45000/- per semester. Hostel Fee with Air Conditioning: Rs. 52000/- per sem. This is the fee structure at quantum university, roorkee",
      },
      {
        question: "Is Quantum University Dehradun private or government?",
        answer1:
          "Quantum University is an Ugc approved private university in Roorkee, Uttarakhand, India.",
      },
      {
        question: "Quantum University Top Recruiters",
        answer1:
          "Capgemini,Wipro,TCS,JANALAXMI,YES BANK",
      },
    ],
    blogNews: [
      {
        img: marwadie1,
        data: "6",
        icon: <FaCalendarAlt />,
        month: "6th November 2024",
        hed: "Internship Workshop.",
        pre: "InternEdge Internship Workshop for Students of Karnawati University",
        txt: "Read More",
        country: "Belgium",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie2,
        data: "30",
        icon: <FaCalendarAlt />,
        month: "30th December 2024",
        hed: "Job Fair.",
        pre: "CareerPro Job Fair for Students of Karnawati University",
        txt: "Read More",
        country: "Austria",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: marwadie1,
        data: "10",
        icon: <FaCalendarAlt />,
        month: "10th January 2025",
        hed: "Career Development Workshop.",
        pre: "FutureCare Career Development Workshop for Students of Karnawati University",
        txt: "Read More",
        country: "Norway",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <Router>
        {/* <TopBar></TopBar> */}
        <Navbar></Navbar>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<UniversityPage uni={uni} />} />
          <Route path="/courses" element={<UniversityPage uni={uni} />} />
          <Route path="/apply-now" element={<UniversityPage uni={uni} />} />
          <Route path="/scholarship" element={<UniversityPage uni={uni} />} />
          {/* Route for the enquiry form */}
          <Route path="/enquiryform" element={<Enquriyform/>} />
          <Route path="/blogpost2" element={<BlogPost2 />} />
          <Route path="/blogpost1" element={<BlogPost1 />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
        <Disclaimer />


        
 <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
        <div className="p-3 bg-gray-300 mb-3 rounded-full">
          <a href ="tel:+919257767661" >
            <IoCall className="text-blue-500" />
          </a>
        </div>
        <div className="p-3 bg-gray-300 mb-2 rounded-full">
          <a href="https://wa.me/+919257767661" target="_blank">
            <IoLogoWhatsapp className="text-green-500" />
          </a>
        </div>
      </div>
      </Router>
    </div>
  );
};

export default App;

import React from "react";
import "./index.css";
import hostel from "./image/hostel.png";
import cafe from "./image/cafe.png";
import lab from "./image/lab.png";
import computerLab from "./image/lab-technician.png";
import medical from "./image/medical-team.png";
import transport from "./image/shipment.png";
// import sports from "./image/sports.png";
import gym from "./image/weightlifter.png";
import wifi from "./image/wifi.png";
import ci from "./image/cinema.png";
// import park from "./image/park.png";
import library from "./image/library (1).png";
// import { FaBook, FaDumbbell, FaCoffee } from "react-icons/fa"; // Import icons from react-icons library

const UniversityFacilities = ({ uni }) => {
  // const facilities = [
  //   { name: 'Library', icon: <FaBook /> },
  //   { name: 'Gym', icon: <FaDumbbell /> },
  //   { name: 'Cafeteria', icon: <FaCoffee /> },
  //   // Add more facilities in a similar format
  // ];

  // Function to render a set of facilities
  // const renderFacilitySet = (start, end) => {
  //   return facilities.slice(start, end).map((facility, index) => (
  //     <div key={index} className="facility">
  //       <div className="icon">{facility.icon}</div>
  //       <div className="name">{facility.name}</div>
  //     </div>
  //   ));
  // };

  return (
    // <div className="university-facilities">
    //   {/* First set of facilities */}
    //   <div className="facility-set">{renderFacilitySet(0, 4)}</div>

    //   {/* Second set of facilities */}
    //   <div className="facility-set">{renderFacilitySet(4, 8)}</div>

    //   {/* Third set of facilities */}
    //   <div className="facility-set">{renderFacilitySet(8, 12)}</div>
    // </div>

    <div id="wrap" className="  flex-wrap  flex  justify-center">
      <div>
        <h2 className="text-bold text-[1.5rem]">
          {uni && uni.university} <span className={`text-[${uni.textColor}]`}>facilities</span>
        </h2>
      </div>

      <div id="wrap" className=" gap-4 flex-wrap flex px-6 w-[90%] justify-center ">
        <div className="linkbutton py-[2rem] ">
          <img
            src={gym}
            className="h-[60px] w-[60px]  "
            alt="image not found"
          ></img>
          <h2>Gym</h2>
          <div className="ease peterriver"></div>
        </div>

        <div className="linkbutton ">
          <img
            src={hostel}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Hostel</h2>
          <div className="ease belizehole"></div>
        </div>

        <div className="linkbutton">
          <img
            src={cafe}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Cafeteria</h2>
          <div className="ease amethyst"></div>
        </div>

        <div className="linkbutton">
          <img
            src={lab}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Laboratory</h2>
          <div className="ease westeria"></div>
        </div>

        <div className="linkbutton">
          <img
            src={computerLab}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Computer Labs</h2>
          <div className="ease wetasphalt"></div>
        </div>

        <div className="linkbutton">
          <img
            src={ci}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Auditorium</h2>
          <div className="ease midnightblue"></div>
        </div>

        <div className="linkbutton">
          <img
            src={wifi}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Wi-Fi Campus</h2>
          <div className="ease sunflower"></div>
        </div>

        <div className="linkbutton">
          <img
            src={transport}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Transport</h2>
          <div className="ease orange"></div>
        </div>

        <div className="linkbutton">
          <img
            src={medical}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Medical</h2>
          <div className="ease carrot"></div>
        </div>

        <div className="linkbutton">
          <img
            src={library}
            className="h-[60px] w-[60px]"
            alt="image not found"
          ></img>
          <h2>Library</h2>
          <div className="ease alizarin"></div>
        </div>
      </div>
    </div>
  );
};

export default UniversityFacilities;


import React, { useState } from 'react';
import image from "./image/quantum logo2.png"
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-[#812972] w-full">
      <div className="w-full flex justify-around px-4 sm:px-6 lg:px-8">
        <div className="flex w-[90%] justify-between h-16 ">
          <div className="flex w-full justify-between">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img className="block lg:hidden h-10 w-auto" src={image} alt="Logo" />
              </Link>
              <Link to="/">
                <img className="hidden lg:block h-[2.5rem] w-auto" src={image} alt="Logo" />
              </Link>
            </div>
            <div className="md:hidden flex items-center">
              <button
                onClick={toggleMenu}
                type="button"
                className="text-black hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {isOpen ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  )}
                </svg>
              </button>
            </div>
            <div className="hidden md:block md:ml-10 md:space-x-4 mt-4">
              <Link to="/" className="text-white hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium">Home</Link>
              <Link to="/" className="text-white hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium">About</Link>
              <Link to="/" className="text-white hover:bg-pink-500 hover:text-white px-3 py-2 rounded-md text-md font-medium">Contact</Link>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden">
            {/* <div className="px-2 pt-4 border-2 pb-3 space-y-1 sm:px-3">
              <Link to="/" className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium">Home</Link>
              <Link to="/about" className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium">About</Link>
              <Link to="/contact" className="text-black hover:bg-pink-600 block px-3 py-2 rounded-md text-base font-medium">Contact</Link>
            </div> */}
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

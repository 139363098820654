import React from "react";
import Container from "../container/Container";
import img1 from "./image/admission img.jpg"
import img2 from "./image/admission2.jpg"

const Admission = ({uni}) => {
  return (
    <div fluid className= {`text-[${uni.textColor}] md:py-[6rem] py-[2rem] `}>
      <div>
        <h1 className="text-center py-8 text-[35px] font-semibold">
          Admission<span className={``}> Process</span> 
        </h1>
        <img
          src={img1}
          alt=""
          className="mx-auto md:block hidden shadow-2xl"
        />
        <img
          src={img2}
          alt=""
          className="mx-auto md:hidden block"
        />
      </div>
    </div>
  );
};

export default Admission;

import React, { useContext, useEffect, useState } from "react";
import { FaArrowRight, FaGraduationCap } from "react-icons/fa6";

function OurEvent({uni}) {
  return (
    <div className="w-full md:py-5 py-2 px-4 lg:px-0 flex justify-center mb-[4rem]  ">
      <div className="container mx-auto">
        <div className="text-center py-4">
          

          <h2 className="font-bold text-3xl">Awards & Accolades</h2>
        </div>
        <div className="flex justify-between flex-wrap">
          {uni &&
            uni.event.map((data, index) => {
              return (
                <div
                  key={index}
                  className="w-full md:w-[48%] lg:w-[30%]  mb-4 group shadow-xl bg-[#f0f7ff]"
                >
                  <div className="overflow-hidden h-[15rem] w-full ">
                    <img
                      src={data.img}
                      alt={data.alt}
                      className="group-hover:scale-125 h-full w-full  transition duration-1000  "
                    />
                  </div>
                  <div className="relative ">
                    <div className="absolute top-[-28%] z-10 left-6 bg-[#812972] p-2  text-white">
                      <h4 className="mb-0">{data.data}</h4>
                      <p className="font-thin mb-0 text-sm">{data.month}</p>
                    </div>
                    <div className="py-6 px-4 bg-[#f0f7ff] group-hover:bg-[#125875] group-hover:text-[#96bece]">
                      <h5 className="group-hover:text-white">{data.hed}</h5>
                      <p className="font-thin truncate">{data.pre}</p>
                      <div className="flex items-center gap-2 text-sm">
                        <p className="font-semibold text-[#777] text-sm group-hover:text-[#96bece]">
                          {data.time}
                        </p>
                        <p>{data.arrow}</p>
                        {/* <p className="text-red-400 group-hover:text-white font-semibold">
                          {data.country}
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default OurEvent;

import React from "react";
// import TopBar from "./TopBar/TopBar";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UniversityBanner from "./university-banner/index";
import OurFeatures from "./OurFeatures/OurFeatures";
import Courses from "./courses";
import OurEvent from "./OurEvent/OurEvent";
import UniversityFacilities from "./facilities/index";
import Testimonals from "./Testimonals/Testimonals";
import Scholarships from "./Scholarships/Scholarships";
import LoanFec from "./LoanFec/LoanFec";
import ConnetQuery from "./contact-query/ConnetQuery";
import HiringPartner from "./hiring-partner/HiringPartner";
import UniFaq from "./uniFaq";
import Admission from "./admission/Admission";
import OurBlog from "./OurBlog/OurBlog";
import OfficeAddress from "./officeAddress";
import OurConnection from "./ourconnection";
import SearchCourses from "./SearchCourses/SearchCourses";
import NewsSlider from "./newsSlider";





function UniversityPage({ uni }) {
  return (
    <div className="w-full overflow-hidden ">
      {/* <TopBar />
      <Navbar/> */}
      {/* <Enquriyform/> */}
      <UniversityBanner uni={uni}/>
      <Courses uni={uni}/>
      <UniversityFacilities uni={uni} />
      <HiringPartner uni={uni} />
      <OurFeatures uni={uni}/>
      <ConnetQuery uni={uni} />
      <Testimonals uni={uni} />
      <Scholarships uni={uni} />
      <LoanFec uni={uni} />
      <OfficeAddress uni={uni} />
      <OurConnection uni={uni} />
      <SearchCourses uni={uni}/>
      <NewsSlider uni={uni} />
      <UniFaq uni={uni} />
      <Admission uni={uni}/>
      {/* <OurEvent uni={uni} /> */}
      {/* <Footer/> */}
       {/* <OurBlog uni={uni} /> */}
    </div>
  );
}

export default UniversityPage;
